import React, {useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {Checkbox, Dropdown, Grid, Icon, Input, Label, Popup} from 'semantic-ui-react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import NMService from '../../../services/nm.service';
import Permissions from '../ModulePermissions/Permissions';
import RunningConfig from '../RunningConfig/RunningConfig';
import ModuleGraph from '../ModuleGraph/ModuleGraph';
import ModuleAlarms from '../ModuleAlarms/ModuleAlarms';
import ModuleConfig from '../ModuleConfig/ModuleConfig';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import ModuleBoxLayout from '../../../layouts/ModuleBox/ModuleBox';
import AnchorStyleButton from '../../../layouts/AnchorStyleButton/AnchorStyleButton';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { addGlobalMessageAtom } from '../../../store/globalMessage';
import { useFetchProbeModuleBundle } from '../../../hooks/useFetchProbeModuleBundle';
import { useFetchSwModules } from '../../../hooks/useFetchSwModules';
import {
    getUsedInstanceDescendantByUuidAtom,
    getUsedInstancePermissionByUuidAtom,
    usedInstancesAtom
} from '../../../store/assignedModules';
import { useFetchGrafanaModuleDashboards } from '../../../hooks/useFetchGrafanaModuleDashboards';
import { useGetGlobalPermission } from '../../../hooks/useGetGlobalPermission';
import { NODE_ADMIN } from '../../../constants/layout';
import { useFetchRegistry } from '../../../hooks/useFetchRegistry';
import { useForm } from 'react-hook-form';
import NMFieldValidator from "../../../utils/NMFieldValidator";
import DateFormat from "../../../components/dateFormat/DateFormat";
import {editProbeAtom} from "../../../store/editProbe";

const AssignedModule = (props) => {
    const probeId = useMemo(() => props.probeId, [props.probeId]);
    const module = useMemo(() => props.moduleData, [props.moduleData]);
    const runningConfig = JSON.parse(module.runconfig);
    const level = useMemo(() => props.level, [props.level]);
    const [configDlIcon, setConfigDlIcon] = useState('')

    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [usedInstancesState, setUsedInstancesState] = useAtom(usedInstancesAtom);
    const hasPermission = useGetGlobalPermission(NODE_ADMIN);
    const moduleHasPermission = useAtomValue(useMemo(() => getUsedInstancePermissionByUuidAtom(module.uuid), [module.uuid]))
    const moduleHasDescendant = useAtomValue(useMemo(() => getUsedInstanceDescendantByUuidAtom(module.uuid), [module.uuid]))
    const suspendMinutesRef = useRef();
    suspendMinutesRef.current = '';
    const [probeState, setProbeState] = useAtom(editProbeAtom)

    const {
        register,
        watch,
        setValue,
        setError,
        clearErrors,
        getValues,
        formState: { errors },
    } = useForm({ });

    const {
        data: moduleDashboards,
        isLoading: moduleDashboardsIsLoading,
    } = useFetchGrafanaModuleDashboards({ moduleid: module.moduleid });

    const {
        data: registryTime,
    } = useFetchRegistry({
        select: (data) => Date.parse(data?.filter((probe) => probe.nodeid === probeId)?.[0]?.data?.modules?.filter(e => e.name === module.moduleid + '.' + module.instanceid && e.version === module.version)?.[0]?.configModified),
        options: { refetchOnMount: 'always' }
    })

    const {
        data: moduleBundle,
        isLoading: moduleBundleIsLoading,
        refetch: refetchModuleBundle
    } = useFetchProbeModuleBundle({
        probeid: probeId,
        options: {
            refetchOnMount: "always"
        }
    });

    const {
        data: swModelList,
        isLoading: swModelListIsLoading,
    } = useFetchSwModules();

    const onRunningFormSubmit = (moduleData, namespaceData) =>
        axios.all([
                NMService.updateNodeModuleRunningConfig(moduleData).catch(e => null),
                NMService.updateNodeNetworkConfig(namespaceData).catch(e => null),
            ])
            .then((r1, r2) =>
                addGlobalMessage({
                    header: 'Running configuration updated!',
                    content: 'Module namespace and running configuration successfully updated.',
                    type: 'positive',
                })).catch(e => null)
                .finally(() => refetchModuleBundle())

    const onConfigFormSubmit = (data) => NMService.updateNodeModuleConfig(data).then(() =>
                                                addGlobalMessage({
                                                    header: 'Module configuration was updated!',
                                                    content: 'Module configuration was successfully updated.',
                                                    type: 'positive',
                                                })).catch(e => null)
                                                .finally(() => refetchModuleBundle())

    const noConfigChangeMessage = () => addGlobalMessage({
                                            header: 'No configuration change made.',
                                            content: 'Module configuration not changed.',
                                            type: 'positive',
                                        })

    const removeHardlink = () => NMService.deleteNMHardlink(probeId, module.templateid)
                                            .then(r => 
                                                addGlobalMessage({
                                                    header: 'Hardlink successfully removed',
                                                    content: "Hardlink successfully removed",
                                                    type: 'positive',
                                            }))
                                            .catch(e => null)
                                            .finally(() => {
                                                selectModuleType('', '')
                                                refetchModuleBundle()
                                            })

    const onPermissionFormSubmitted = () => {
        addGlobalMessage({
            header: 'Module permission was updated!',
            content: `Module permission was successfully updated.`,
            type: 'positive',
        });
    };

    const unassignModule = async () => {
        const uuid = usedInstancesState.selectedModuleUuid;
        if (!uuid) return;

        if (!!getValues('deleteGraphs')) {
            await NMService.getNodeModuleGraph(probeId, module.moduleid, module.instanceid)
                            .catch(e => null)
                            .then((r) => r.data.map(e => e.uid))
                            .then(async r => await Promise.all(r.map(e => NMService.deleteNodeModuleGraph(e).catch(e => null)))
                                                .then((e => e.some(e => e === null) ? addGlobalMessage({
                                                    header: 'Some or all graphs could not be deleted',
                                                    content: "Some graphs could not be deleted",
                                                    type: 'warning',
                                                }) : addGlobalMessage({
                                                    header: 'Removed all associated graphs',
                                                    content: "Graph dashboards successfully removed",
                                                    type: 'positive',
                                                }))).catch(e => null))
        }

        await NMService.unassignModule(uuid).then(() => {
            addGlobalMessage({
                header: 'Module was unassigned!',
                content: 'Module was successfully unassigned.',
                type: 'positive',
            });
            selectModuleType('', '');
            refetchModuleBundle();
        }).catch(e => null)

        const framework = usedInstancesState.frameworks.find(e => e.uuid === module.parent)
        if (framework === undefined || framework.moduleid === 'ethernetframework') {
            refetchModuleBundle()
            return
        }

        NMService.getNodeModule(framework.nodeid, framework.moduleid, framework.instanceid).then(
            r => {
                const newModule = `${module.moduleid}.${module.instanceid}`
                let conf = JSON.parse(r.data.config)
                if (framework.moduleid === "mobileframework") {
                    conf = {...conf, modules: ([...((conf.modules ?? []).filter(e => e.name !== newModule))])}
                } else if (framework.moduleid !== "ethernetframework") {
                    conf = {...conf, modules: ([...((conf.modules ?? []).filter(e => e !== newModule))])}
                }
                return conf
            }).then(r => 
                NMService.updateNodeModuleConfig({
                    nodeid: framework.nodeid,
                    moduleid: framework.moduleid,
                    instanceid: framework.instanceid,
                    config: JSON.stringify(r)
                })
            ).then(() => refetchModuleBundle())
            .catch(e => null)
    };

    const selectModuleType = (uuid, moduleType) => {
        setUsedInstancesState({
            type: 'select-module',
            value: {
                selectedModuleUuid: uuid,
                showModuleType: moduleType
            }
        })
    }

    useEffect(() => {
        const getIcon = () => {
            if (module.moduleid === 'ethernetframework') {
                return 'checkmark'
            }
            const now = new Date(Date.parse(new Date().toISOString().slice(0, -1))).getTime()
            const created = Date.parse(module.created?.slice(0, -1))

            if (isNaN(registryTime))
                if (now - created < 120000) { return 'sync' }
                else { return 'close' }

            const updated = Date.parse(module.configUpdated?.slice(0, -1))
            if (isNaN(updated) || updated === created) { return 'checkmark' }

            if (registryTime - updated >= 0) { return 'checkmark' }
            if (now - updated >= 0 && now - updated < 120000) { return 'sync' }
            if (registryTime - updated < 0) { return 'close' }

            return 'checkmark'
        }
        setConfigDlIcon(getIcon())
    }, [registryTime, module.configUpdated, module.created, module.moduleid])

    const toggleSuspendMeasurements = () => {

        let rc = runningConfig;
        let shouldResume = rc?.suspended;

        let data = {
            duration: Number(suspendMinutesRef.current),
            suspend: !shouldResume,
            unit: level === 1 ? 'framework' : 'instance',
            nodeID: Number(probeId),
            id: module.uuid
        }

        if (!shouldResume) {
            let validatorMessage = NMFieldValidator.validateInt(suspendMinutesRef.current, 0, undefined, true)
            if (validatorMessage != null) {
                setError('suspendMeasurements', {message: validatorMessage, type: "manual"});
                return;
            }
        } else {
            delete data.duration
        }

        NMService.suspendMeasurements(data).then(() => {
            addGlobalMessage({
                header: `Measurements are ${shouldResume ? 'resumed' : 'suspended'}!`,
                content: `Measurements are successfully ${shouldResume ? 'resumed' : 'suspended'} for module ${module.moduleid} with id ${module.instanceid}!`,
                type: 'positive',
            });
        })
        .catch(e => null)
        .finally(() => {
            refetchModuleBundle();
            clearErrors('suspendMeasurements');
            suspendMinutesRef.current = '';
        });
    };

    const isSelected = Boolean(usedInstancesState.selectedModuleUuid === module.uuid);

    return (moduleBundleIsLoading || swModelListIsLoading || moduleDashboardsIsLoading) ? null : (
        <>
            <ModuleBoxLayout level={level} hardlinked={module.hardlinked}>
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {module.moduleid} (v{module.version})
                        </Grid.Column>
                        <Grid.Column width={2}>{module.instanceid}</Grid.Column>
                        <Grid.Column width={3}>
                            {module.hardlinked && (
                                <>
                                    Template:&nbsp;
                                    {<Link to={'../../templates'}>{module.templateid} / {module.templatename}</Link>}
                                </>
                            )}
                        </Grid.Column>
                        <Grid.Column width={6}>
                            {!module.hardlinked &&
                                <PermissionsGateV hasPermission={moduleHasPermission}>
                                    <AnchorStyleButton
                                        selected={isSelected && usedInstancesState.showModuleType === 'unassignModule'}
                                        disabled={moduleHasDescendant}
                                        onClick={() => selectModuleType(module.uuid, 'unassignModal')}
                                    >
                                        <Icon name="minus" /> unassign
                                    </AnchorStyleButton>
                                </PermissionsGateV>}
                            {module.moduleid !== "ethernetframework" && 
                                <>
                                    &emsp;
                                    <AnchorStyleButton
                                        selected={isSelected && usedInstancesState.showModuleType === 'moduleConfig'}
                                        onClick={() => {
                                            if (isSelected && usedInstancesState.showModuleType === 'moduleConfig') {
                                                selectModuleType('', '')
                                            } else {
                                                selectModuleType(module.uuid, 'moduleConfig')
                                            }
                                        }}
                                    >
                                        <Icon name="cog" /> module config
                                    </AnchorStyleButton>
                                </>
                            }
                            &emsp;
                            <AnchorStyleButton
                                selected={isSelected && usedInstancesState.showModuleType === 'runningConfig'}
                                onClick={() => {
                                    if (isSelected && usedInstancesState.showModuleType === 'runningConfig') {
                                        selectModuleType('', '')
                                    } else {
                                        selectModuleType(module.uuid, 'runningConfig')
                                    }
                                }}
                            >
                                <Icon name="cog" /> running config
                            </AnchorStyleButton>

                            {module.hardlinked &&
                                <>
                                    &emsp;
                                    <PermissionsGateV hasPermission={hasPermission}>
                                        <AnchorStyleButton
                                            selected={isSelected && usedInstancesState.showModuleType === 'removeHardlink'}
                                            onClick={() => selectModuleType(module.uuid, 'removeHardlink')}
                                        >
                                            <Icon name="trash" /> remove hardlink
                                        </AnchorStyleButton>
                                    </PermissionsGateV>
                                </>
                            }
                            &emsp; &emsp;&emsp;
                            <Dropdown
                                icon="ellipsis horizontal"
                                style={{
                                    color: '#4183c4',
                                    fontWeight:
                                        isSelected &&
                                        (
                                            usedInstancesState.showModuleType === 'moduleGraph' ||
                                            usedInstancesState.showModuleType === 'moduleAlarm' ||
                                            usedInstancesState.showModuleType === 'modulePermissions'
                                        ) &&
                                        'bold',
                                }}
                            >
                                <Dropdown.Menu>
                                    {moduleDashboards.length !== 0 && <Dropdown.Item
                                        text="Graph"
                                        style={{
                                            fontWeight: isSelected && usedInstancesState.showModuleType === 'moduleGraph' && 'bold',
                                            color: '#4183c4',
                                        }}
                                        onClick={() => selectModuleType(module.uuid, 'moduleGraph')}
                                    />}
                                    <Dropdown.Item
                                        text="Alarms"
                                        style={{
                                            fontWeight: isSelected && usedInstancesState.showModuleType === 'moduleAlarm' && 'bold',
                                            color: '#4183c4',
                                        }}
                                        onClick={() => selectModuleType(module.uuid, 'moduleAlarm')}
                                    />
                                    {!module.hardlinked && (
                                        <Dropdown.Item
                                            text="Permissions"
                                            style={{
                                                fontWeight:
                                                    isSelected &&
                                                    usedInstancesState.showModuleType === 'modulePermissions' &&
                                                    'bold',
                                                color: '#4183c4',
                                            }}
                                            onClick={() => selectModuleType(module.uuid, 'modulePermissions')}
                                        />
                                    )}
                                    {/*{(runningConfig?.runmethod === 'ondemand' || (runningConfig?.period && !module.hardlinked)) && (
                                        <Dropdown.Divider />
                                    )}*/}

                                    {/*{runningConfig?.runmethod === 'ondemand' && (
                                        <PermissionsGateV hasPermission={hasPermission}>
                                    )}
                                    {runningConfig?.runmethod === 'ondemand' && (
                                        <PermissionsGateV hasPermission={hasPermission && probeState.license.expiryDays >= 0}>
                                            <Dropdown.Item
                                                icon="play"
                                                text="Execute"
                                                style={{ color: '#4183c4' }}
                                                onClick={() => NMService.executeOnDemand({
                                                    "nodeid": probeId,
                                                    "moduleid": module.moduleid,
                                                    "instanceid": module.instanceid,
                                                    "command": "execute"
                                                }).then(r =>
                                                    addGlobalMessage({
                                                        header: 'Module execute started',
                                                        content: `Module ${module.moduleid}-${module.instanceid} started`,
                                                        type: 'positive',
                                                })).catch(e => null)}
                                            />
                                        </PermissionsGateV>
                                    )}*/}

                                    { (!runningConfig?.suspended || // for the button to be visible it has to be either unsuspended or suspended by its own level
                                      ((runningConfig?.suspendedBy === 'framework' && level === 1) ||
                                       (runningConfig?.suspendedBy === 'instance' && level !== 1))) && (
                                        <>
                                            <Dropdown.Divider />
                                            <PermissionsGateV hasPermission={hasPermission}>
                                                <Dropdown.Item
                                                    icon={runningConfig?.suspended &&
                                                    ((runningConfig?.suspendedBy === 'framework' && level === 1) ||
                                                     (runningConfig?.suspendedBy === 'instance' && level !== 1)) ? "play" : "pause"}
                                                    text={runningConfig?.suspended &&
                                                    ((runningConfig?.suspendedBy === 'framework' && level === 1) ||
                                                     (runningConfig?.suspendedBy === 'instance' && level !== 1)) ? "Resume" : "Suspend"}
                                                    style={{ color: '#4183c4' }}
                                                    onClick={() => selectModuleType(module.uuid, 'suspendMeasurements')}
                                                />
                                            </PermissionsGateV>
                                        </>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Grid.Column>
                        <Grid.Column width={1}>
                            {runningConfig?.suspended && (
                                <Popup
                                    on={'hover'}
                                    wide
                                    position={"right center"}
                                    style={{textAlign: "center"}}
                                    trigger={<div style={{ cursor: 'pointer' }}><Icon name='pause' color='red'/></div>}
                                >
                                    <>
                                        measurements suspended <br/>
                                        {runningConfig?.suspended === 'indefinitely' ? runningConfig.suspended : (<>until <DateFormat timestamp={runningConfig.suspended}/></>)}
                                        <br/>
                                        {runningConfig?.suspendedBy === 'system' ||
                                        runningConfig?.suspendedBy === 'tenant' ? `by ${runningConfig?.suspendedBy} admin` :
                                        (runningConfig?.suspendedBy === 'node' ? 'by node' : (runningConfig?.suspendedBy === 'framework' && level !== 1 ? 'by parent framework' : ''))}
                                    </>
                                </Popup>
                            )}
                        </Grid.Column>
                        <Grid.Column width={1}>
                            {isSelected && usedInstancesState.showModuleType !== 'unassignModule' && (
                                <AnchorStyleButton
                                    selected={isSelected}
                                    onClick={() => selectModuleType('', '')}
                                    style={{ margin: 0, padding: 0 }}
                                >
                                    <Icon name="close" />
                                </AnchorStyleButton>
                            )}
                            <Icon
                                link
                                name={configDlIcon}
                                color={configDlIcon === 'checkmark' ? 'green' : configDlIcon === 'close' ? 'red' : 'orange'}
                                title={configDlIcon === 'checkmark' ? 'Module configuration up to date' : configDlIcon === 'close' ? 'Module configuration not up to date' : 'Module configuration recently updated (could take few minutes to take effect)'}
                                style={{ float: 'right', margin: 0, padding: 0 }}
                            />
                            <span
                                style={{ float: 'right', cursor: 'pointer', marginRight: '10px', fontWeight: 600, color: '#4183c4' }}
                                title={runningConfig?.runmethod === 'ondemand' ? "On demand" :
                                            runningConfig?.runmethod === 'asservice' ? "As service" :
                                                `${runningConfig?.period?.minute} ${runningConfig?.period?.hour} ${runningConfig?.period?.dayOfMonth} ${runningConfig?.period?.month} ${runningConfig?.period?.dayOfWeek} `
                                            }
                            >
                                {runningConfig?.runmethod === 'ondemand' ? "D" : runningConfig?.runmethod === 'asservice' ? "S" : "P"}
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                    {isSelected && usedInstancesState.showModuleType !== 'unassignModule' && (
                        <Grid.Row>
                            <Grid.Column>
                                {usedInstancesState.showModuleType === 'runningConfig' && (
                                    <RunningConfig
                                        top={props.top}
                                        probeId={probeId}
                                        data={module}
                                        parentRunningConfig={props.parentRunningConfig}
                                        hasPermission={moduleHasPermission}
                                        onFormSubmit={onRunningFormSubmit}
                                        noConfigChangeMessage={noConfigChangeMessage}
                                    />
                                )}

                                {usedInstancesState.showModuleType === 'moduleConfig' && (
                                    <ModuleConfig
                                        probeId={probeId}
                                        data={module}
                                        hasPermission={moduleHasPermission && probeState.license.expiryDays >= 0}
                                        onFormSubmit={onConfigFormSubmit}
                                        noConfigChangeMessage={noConfigChangeMessage}
                                    />
                                )}

                                {usedInstancesState.showModuleType === 'modulePermissions' && (
                                    <Permissions
                                        probeId={probeId}
                                        data={module}
                                        hasPermission={moduleHasPermission}
                                        onFormSubmitted={onPermissionFormSubmitted}
                                    />
                                )}

                                {usedInstancesState.showModuleType === 'moduleAlarm' && (
                                    <ModuleAlarms
                                        probeId={probeId}
                                        moduleData={module}
                                        hasPermission={moduleHasPermission}
                                    />
                                )}
                                {usedInstancesState.showModuleType === 'moduleGraph' && (
                                    <ModuleGraph
                                        probeId={probeId}
                                        data={module}
                                        hasPermission={moduleHasPermission}
                                    /* onDismissForm={onDismissGraphForm} */
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </ModuleBoxLayout>

            {(moduleBundle.assignedmodules || [])
                .filter(e => (
                    e.parent === module.uuid && swModelList.find(model => (
                        model.id === e.moduleid && model.data.framework && !model.data.toplevel
                    ))
                ))
                .map((elem) => (
                    <AssignedModule
                        key={elem.uuid}
                        probeId={probeId}
                        level={level + 1}
                        moduleData={elem}
                    />
                ))
            }

            {(moduleBundle.assignedmodules || [])
                .filter(e => (
                    e.parent === module.uuid && swModelList.find(model => (
                        model.id === e.moduleid && !model.data.framework && !model.data.toplevel
                    ))
                ))
                .map((elem) => (
                    <AssignedModule
                        top={props.top}
                        key={elem.uuid}
                        probeId={probeId}
                        level={level + 1}
                        moduleData={elem}
                        parentRunningConfig={runningConfig}
                    />
                ))
            }

            <ConfirmationModal
                open={usedInstancesState.showModuleType === 'removeHardlink' && isSelected}
                header="Remove hardlink from node?"
                content={<>Are you sure you want to remove template hardlink from node?<pre style={{ color: "red" }}>Note: this will remove all associated modules from node!</pre></>}
                onConfirm={removeHardlink}
                onDismiss={() => selectModuleType('', '')}
            />
            <ConfirmationModal
                open={usedInstancesState.showModuleType === 'unassignModal' && isSelected}
                header="Unassign instance"
                content={<>
                        Are you sure you want to unassign instance? <br/><br/>
                        Delete module graphs associated with this node module instance?
                        <Checkbox
                            {...register('deleteGraphs')}
                            style={{ marginLeft: '1rem', paddingTop: '0.25rem' }}
                            onClick={(e) => setValue('deleteGraphs', !watch('deleteGraphs'))}
                        />
                    </>}
                onConfirm={unassignModule}
                onDismiss={() => selectModuleType('', '')}
            />
            <ConfirmationModal
                open={usedInstancesState.showModuleType === 'suspendMeasurements' && isSelected}
                header={`${runningConfig?.suspended ? "Resume" : "Suspend"} measurements`}
                content={
                    <>
                        {runningConfig?.suspended ? "Resume" : "Suspend"} measurements for {module.moduleid} with id {module.instanceid}<br/>
                        <pre style={{ color: "red" }}>
                            This action will {runningConfig?.suspended ? "resume" : "suspend"} all measurements for module {module.moduleid} with id {module.instanceid}
                        </pre>
                        {!runningConfig?.suspended && <Input>
                            <input
                                placeholder={'Suspend time [minutes]'}
                                type={'number'}
                                min={0}
                                style = {{width: 200}}
                                onChange={(e) => suspendMinutesRef.current = e.target.value}
                            />

                            {errors.suspendMeasurements?.type === "manual" && (
                                <Label basic color="red" pointing="left" content={errors.suspendMeasurements.message}/>
                            )}
                        </Input>}
                    </>}
                onConfirm={toggleSuspendMeasurements}
                onDismiss={() => {
                    clearErrors('suspendMeasurements');
                    suspendMinutesRef.current = '';
                    selectModuleType('', '')
                }}
            />
        </>
    );
};

export default AssignedModule;