
const parseFloat = (o, name, level, parentType, path, unit = undefined) => {
    return [
        {
            name: name,
            path: path + name,
            type: o['type'],
            description: o['description'],
            operators: ['>', '<'],
            unit: unit
        },
    ];
};

const parseInt = (o, name, level, parentType, path, unit = undefined) => {
    return [
        {
            name: name,
            path: path + name,
            type: o['type'],
            description: o['description'],
            operators: ['>', '<', '=='],
            unit: unit
        },
    ];
};

const parseString = (o, name, level, parentType, path, unit = undefined) => {
    return [
        {
            name: name,
            path: path + name,
            type: o['type'],
            description: o['description'],
            operators: ['==', '~='],
            unit: unit
        },
    ];
};

export const parseObject = (o, name, level, parentType, path) => {
    let rv = [];
    Object.keys(o).forEach((item) => {
        if (!('inalarm' in o[item]) || o[item]['inalarm'] === false) { return }
        switch (o[item]['type']) {
            case 'object':
                rv = rv.concat(
                    parseString(o[item], item, level + 1, 'object', path + name + '->', o[item]?.unit)
                );
                break;
            case 'string':
                rv = rv.concat(parseString(o[item], item, level + 1, 'object', path, o[item]?.unit));
                break;
            case 'uint8':
            case 'uint16':
            case 'uint32':
            case 'uint64':
                rv = rv.concat(parseInt(o[item], item, level + 1, 'object', path, o[item]?.unit));
                break;
            case 'float':
            case 'ufloat':
                rv = rv.concat(parseFloat(o[item], item, level + 1, 'object', path, o[item]?.unit));
                break;
            default:
                break;
        }
    });
    return rv;
};

const getRuleComponent = (rule) => {
    const reRuleComponent = /^[ ]*(and|or|not)?[ ]*((\$[a-zA-Z]+[a-zA-Z0-9_]*)(->[a-zA-Z]+[a-zA-Z0-9]*)*) ([<>=~]+) (.*)$/;
    let match = rule.match(reRuleComponent);
    if (match == null) { return null }
    return { boolOp: match[1], variable: match[2], oper: match[5], value: match[6] };
};

export const splitRule = (rule) => {
    return rule
        .replace(/ and \$/g, ' ;and $')
        .replace(/ or \$/g, ' ;or $')
        .split(';')
        .map((e) => getRuleComponent(e))
        .filter((e) => e);
};

export const formatRuleDefinition = (alarm, resultModels) => {
    if (!resultModels) return;
    const moduleInfo = (resultModels || []).find((e) => e.id === alarm.module);
    let formattedrule = alarm.rule;
    if (moduleInfo && moduleInfo.hasOwnProperty('resultmodel')) {
        moduleInfo.resultmodel.forEach((e) => {
            formattedrule = formattedrule.replace(e.path, e.description);
        });
    }
    return String(formattedrule);
}

const checkIpAddress = (value) => {
    const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
    if (!regexExp.test(value)) { return 'value is not valid IP address' }
};

export const validateRuleValue = (value, variablePath, variableList) => {
    if (!value) { return 'Field is mandatory' }
    const variableType = (variableList || []).find((e) => e.path === variablePath)?.type;
    return validateValue(value, variableType);
};

export const validateValue = (value, type) => {
    const intArr = ['int', 'uint8', 'uint16', 'uint32', 'float'];
    if (!value.trim()) { return 'Field is mandatory' }
    if (type === 'string') { return undefined }
    if (type === 'ipaddress') { return checkIpAddress(value) }

    if (intArr.includes(type)) {
        if (isNaN(value)) { return 'Value is not valid integer' }
    }
};