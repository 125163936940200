import React from "react";
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    defer
} from "react-router-dom";

import Login from './pages/login/Login';
import { ProtectedLayout } from "./layouts/ProtectedLayout";
import { AuthLayout } from "./layouts/AuthLayout";
import { HomeLayout } from "./layouts/HomeLayout";
import { MultiTenantLayout } from "./layouts/MultiTenantLayout";
import { ProtectedLicenseLayout } from "./layouts/ProtectedLicenseLayout";
import { ProtectedDhcpLayout } from "./layouts/ProtectedDhcpLayout";
import { ProtectedAuthBackendLayout } from "./layouts/ProtectedAuthBackendLayout";
import { ProtectedProbeLayout } from "./layouts/ProtectedProbeLayout";
import { ReturnUrlLayout } from "./layouts/ReturnUrlLayout";

//pages
import Overview from './pages/overview/overview';
import Nodes from './pages/nodes/Nodes';
import Images from './pages/images/Images';
import ProbeEdit from './pages/managedNodeEdit/ProbeEdit';
import Templates from './pages/templates/Templates';
import Alarms from './pages/alarms/Alarms';
import SWModule from './pages/swmodule/SWModule';
import SWModuleDetails from './pages/swmodule/SWModuleDetails';
import Locations from './pages/locations/Locations';
import LocationParameters from './pages/locationParameters/LocationParameters';
import GlobalConfiguration from './pages/globalConfiguration/GlobalConfiguration';
import DhcpSubnets from './pages/services/DhcpSubnets/DhcpSubnets';
import DhcpGlobalConfiguration from './pages/services/DhcpGlobalConfiguration'
import DhcpOptions from './pages/services/DhcpOptions/DhcpOptions';
import LocalUser from './pages/userMgmt/LocalUser';
import Groups from './pages/groups/Groups';
import Manage from './pages/authBackends/Manage';
import Destinations from "./pages/objectService/destinations/Destinations";
import ObjectData from "./pages/objectService/objectData/ObjectData";
import Reports from "./pages/reporting/Reports";
import LicenseManagment from "./pages/licenseManagment/LicenseManagment";
import MLAlarms from "./pages/mlAlarms/MLAlarms";
import MultiTenant from "./pages/multiTenant/MultiTenant";
import Snmp from "./pages/snmp/Snmp";
import NotificationSettings from "./pages/alarms/notifications/NotificationSettings";
import SkippedMeasurements from "./pages/alarms/skippedMeasurements/SkippedMeasurements";
import NotFound from "./pages/error/NotFound";

const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
            const user = window.sessionStorage.getItem("user");
            resolve(JSON.parse(user));
        }, 500)
    );

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
        >
            <Route element={<ReturnUrlLayout />}>
                <Route element={<ProtectedLayout />}>
                    <Route element={<HomeLayout />}>
                        <Route path="*" element={<NotFound />}/>
                        <Route exact path="/" element={<Overview />} />
                        <Route path="/nodes" element={<Nodes />} />
                        <Route path="/node/:type/" element={<ProtectedProbeLayout />}>
                            <Route path=":probeid" element={<ProbeEdit />} />
                        </Route>
                        <Route path="/images" element={<Images />} />
                        <Route path="/alarms/all-alarms" element={<Alarms />} />
                        <Route path="/alarms/machine-learning" element={<MLAlarms />} />
                        <Route path="/alarms/notifications" element={<NotificationSettings />} />
                        <Route path="/alarms/skipped-measurements" element={<SkippedMeasurements />} />
                        <Route path="/templates" element={<Templates />} />
                        <Route exact path="/module" element={<SWModule />} />
                        <Route path="/module/:name/:version" element={<SWModuleDetails />} />
                        <Route path="/locations/list" element={<Locations />} />
                        <Route path="/locations/params" element={<LocationParameters />} />
                        <Route element={<ProtectedDhcpLayout />}>
                            <Route path="/services/dhcp/subnets" element={<DhcpSubnets />} />
                            <Route path="/services/dhcp/global" element={<DhcpGlobalConfiguration />} />
                            <Route path="/services/dhcp/options" element={<DhcpOptions />} />
                        </Route>
                        {/* <Route element={<ProtectedAuthBackendLayout />}>
                            <Route path="/manage" element={<Manage />} />
                        </Route> */}
                        <Route path="/localuser" element={<LocalUser />} />
                        <Route path="/groups" element={<Groups />} />
                        <Route path="/localusergroups" element={<Groups />} />
                        <Route path="/reporting" element={<Reports />} />
                        <Route path="/configuration" element={<GlobalConfiguration />} />
                        <Route path="/objserv/destinations" element={<Destinations />} />
                        <Route path="/objserv/objdata" element={<ObjectData />} />
                        <Route path="/snmp" element={<Snmp />} />
                        <Route element={<ProtectedLicenseLayout />}>
                            <Route path="/license" element={<LicenseManagment />} />
                        </Route>
                    </Route>
                    <Route element={<MultiTenantLayout />}>
                        <Route path="/multitenant" element={<MultiTenant />} />
                    </Route>
                </Route>
            </Route>
            <Route path="/login" element={<Login />} />
        </Route>
    )
);
