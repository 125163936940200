import React, { useEffect, useMemo, useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { useForm, useFieldArray } from 'react-hook-form';
import NMService from '../../services/nm.service';
import { addGlobalMessageAtom } from '../../store/globalMessage';
import { useSetAtom } from 'jotai';
import { useFormFields } from '../../hooks/useFormFields';

function VisualizationDashTemplateForm(props) {
    const [moduleid, setModuleId] = useState('');
    const [visDashTemplateId, setVisDashTemplateId] = useState('');
    const visDashTemplates = useMemo(() => props.visDashTemplates, [props.visDashTemplates]);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const {
        register,
        reset,
        watch,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'variables',
    });

    const onSubmit = (values) => {
        let { variables, template, id, iteratefield, templatetype, isdefault } = values;
        try {
            template = JSON.parse(template)
        } catch {
            template = {}
        }

        let data = {
            moduleid: moduleid,
            id: id.trim(),
            variables: {},
            iteratefield: iteratefield.trim(),
            template: template,
            templatetype,
            isdefault
        }

        if (Array.isArray(variables)) {
            variables.forEach((e) => {
                const key = '${' + e.variable.trim() + '}';
                const value = e.description.trim();
                data.variables[key] = value;
            });
        }

        NMService.updateSWModuleVisDashTemplate(data).then(() => {
            props.closeForm();
            props.refreshTemplates();
            addGlobalMessage({
                header: `Visualization dashboard template was ${visDashTemplateId ? 'updated' : 'created'}!`,
                content: `Visualization dashboard template was successfully ${visDashTemplateId ? 'updated' : 'created'}.`,
                type: 'positive',
            })
        }).catch(e => null)
    };

    useEffect(() => {
        setModuleId(props.moduleid);

        const defaultconfig = {
            iteratefield: '',
            templatetype: 2,
            isdefault: 0,
        }

        let initconfig = {};
        if (props.selectedVisDashTemplateId) {
            setVisDashTemplateId(props.selectedVisDashTemplateId);
            try {
                const { variables, template, ...rest } = visDashTemplates.find(
                    (e) => e.id === props.selectedVisDashTemplateId
                );

                const variablesArr = [];
                for (const item in variables) {
                    variablesArr.push({
                        variable: item.substring(item.indexOf('{') + 1, item.lastIndexOf('}')),
                        description: variables[item],
                    });
                }

                initconfig = {
                    ...rest,
                    variables: variablesArr,
                    template: JSON.stringify(template, null, 2),
                };
            } catch {
                initconfig.template = JSON.stringify(
                    {
                        dashboard: {},
                    },
                    null,
                    2
                );
            }
        } else {
            setVisDashTemplateId('');
        }
        reset({ ...defaultconfig, ...initconfig });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedVisDashTemplateId, props.moduleid, visDashTemplates]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '2rem', marginLeft: '2rem' }}>
            <Form.Group widths={4}>
                {renderInput('Template ID', 'id', { disabled: Boolean(props.selectedVisDashTemplateId) })}
                {renderInput('Iterate field', 'iteratefield', { notRequired: true })}
            </Form.Group>
            <Form.Group widths={4}>
                <Form.Field inline>
                    Variables&ensp;
                    <Icon
                        name="plus"
                        link
                        style={{ color: '#4183C4' }}
                        onClick={() => append({ variable: '', description: '' })}
                    />
                </Form.Field>
            </Form.Group>
            {fields.map((field, index) => (
                <Form.Group widths={4} key={field.id}>
                    <Form.Field>
                        <Icon
                            link
                            name="trash alternate"
                            style={{ color: '#4183C4', marginLeft: '1rem', float: 'right' }}
                            onClick={() => remove(index)}
                        />
                    </Form.Field>
                    {renderInput(`Variable ${index} name`, `variables.${index}.variable`, { hideLabel: true })}
                    {renderInput(`Variable ${index} description`, `variables.${index}.description`, { hideLabel: true, notRequired: true })}
                </Form.Group>
            ))}
            <Form.Group style={{ marginTop: '2rem' }}>
                {renderInput('Template', 'template', { width: 16, inputType: 'textarea', rows: 15 })}
            </Form.Group>
            <Form.Group>
                <div title={props.disabled ? "Global templates can only be edited in the 'default' tenant" : undefined}>
                    <Form.Button
                        disabled={props.disabled}
                        size="small"
                        primary
                        content={visDashTemplateId ? 'Update' : 'Create'}
                    />
                </div>
                {visDashTemplates.length > 0 && <Form.Button size="small" type="button" content='Cancel' onClick={props.closeForm}/>}
            </Form.Group>
        </Form>
    );
}

export default VisualizationDashTemplateForm;