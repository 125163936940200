import { Button, Grid, Icon, Popup } from "semantic-ui-react";
import { PermissionsGateV } from "../../layouts/PermissionGate/PermissionGateV";
import {useEffect, useMemo, useState} from "react";
import DateFormat from "../../components/dateFormat/DateFormat";
import {useAtom} from "jotai/index";
import {editProbeAtom} from "../../store/editProbe";
import { getExpiryColor, EXPIRY_DAYS_WARNING } from "../../utils/LicenseExpiry";

const ProbeLicense = (props) => {
    const probeLicense = useMemo(() => props.probeLicense, [props.probeLicense])
    const availableUpgradeLicense = useMemo(() => props.availableUpgradeLicense, [props.availableUpgradeLicense])
    const hasPermission = useMemo(() => props.hasPermission, [props.hasPermission])

    const [probeState, setProbeState] = useAtom(editProbeAtom);

    const switchName = (name) => {
        switch (name) {
            case "name":
                return "Name";
            case "description":
                return "Description";
            case "licenseType":
                return "License type";
            case "company":
                return "Company";
            case "quantity":
                return "Quantity";
            case "serialNumber":
                return "Serial number";
            case "licenseKey":
                return "License key";
            case "issueDate":
                return "Issue date";
            case "licenseCode":
                return "License code";
            case "features":
                return "Features";
            case "active":
                return "Active";
            case "sla":
                return "SLA";
            case "warranty":
                return "Warranty";
            case "Updates":
                return "Updates";
            default:
                return name
        }
    };

    return (
        <label style={{ float: 'right', fontWeight: '500', margin: '1rem 0 0 0', paddingBottom: '0' }}>
            {probeLicense && probeLicense?.name ?
            <>
                    {typeof probeState.license.expiryDays === 'number' && // checking only "probeState.license.expiryDays" doesn't work when the value is 0
                        probeState.license.expiryDays <= EXPIRY_DAYS_WARNING ? (
                            <Popup
                                position="bottom center"
                                trigger={<Icon link
                                               color={getExpiryColor(probeState.license.expiryDays, true)}
                                               name="warning circle" />}
                                on='hover'
                                style={{ padding: "1rem" }}
                            >
                                <Popup.Content>
                                    {probeState.license.expiryDays < 0 ?
                                        'License has expired' :
                                            probeState.license.expiryDays === 0 ?
                                            `License is expiring today`
                                                :
                                            `License will expire in ${probeState.license.expiryDays} days`
                                    }
                                </Popup.Content>
                            </Popup>
                        ) : null
                    }
                <span style={{ color: getExpiryColor(probeState.license.expiryDays)}}>
                    License: {probeLicense?.name}&ensp;
                </span>
                <Popup
                    position="bottom left"
                    trigger={<Icon link circular color='blue' size="small" name="info" />}
                    on='click'
                    style={{ minWidth: "400px", padding: "2rem 0 2rem 0" }}
                >
                    <Popup.Content>
                        <Grid>
                            {Object.keys(probeLicense).map((elem) =>
                                (elem !== "uuid" && elem !== "remaining" && probeLicense[elem]) ? (
                                    <Grid.Row key={elem} style={{ padding: "2px 10px 2px 10px" }}>
                                        <Grid.Column width={6} textAlign="right" style={{ fontWeight: "bold" }}>
                                            {switchName(elem)} :
                                        </Grid.Column>
                                        <Grid.Column width={10}>
                                            {elem === "active"
                                                ? probeLicense[elem]
                                                    ? "true"
                                                    : "false"
                                                : probeLicense[elem]}
                                        </Grid.Column>
                                    </Grid.Row>)
                                    : null
                            )}
                        </Grid>
                    </Popup.Content>
                </Popup>
                {(availableUpgradeLicense && Object.keys(availableUpgradeLicense).length !== 0) &&
                    <Popup
                        content="Upgrade license"
                        trigger={
                            <PermissionsGateV hasPermission={hasPermission}>
                                <Icon
                                    link
                                    circular
                                    color='blue'
                                    size="small"
                                    name="upload"
                                    onClick={() => props.setShowModal('upgradeLicense')}
                                />
                            </PermissionsGateV>}
                    />}
                {probeLicense &&
                    <Popup
                        content="Remove license"
                        trigger={
                            <PermissionsGateV hasPermission={hasPermission}>
                                <Icon
                                    link
                                    circular
                                    color='red'
                                    size="small"
                                    name="trash"
                                    onClick={() => props.setShowModal('removeLicense')}
                                />
                            </PermissionsGateV>}
                    />}
            </>
            :
            <PermissionsGateV hasPermission={hasPermission}>
                <Button
                    content="Add node license"
                    color="red"
                    size="tiny"
                    onClick={() => props.setShowModal('assignLicense')}
                />
            </PermissionsGateV>}
        </label>
)}

export default ProbeLicense;