
import { atomWithReducer } from 'jotai/utils';

export const licenseMgmtActions = {
    SHOW_LICENSE_FORM: 'show-license-form',
    SHOW_LICENSE_INFO: 'show-license-info',
    SHOW_DELETE_LICENSE: 'show_delete_license',
    RESET: 'reset',
    CLOSE_FORM: 'close-any-form'
}

const INITIAL_STATE = {
    showType: '',
    selectedLicense: {},
}

const licenseMgmtReducer = (prevState, { type, value }) => {
    switch (type) {
        case licenseMgmtActions.SHOW_LICENSE_INFO:
            return {
                ...prevState,
                selectedLicense: value,
                showType: licenseMgmtActions.SHOW_LICENSE_INFO,
            };
        case licenseMgmtActions.SHOW_LICENSE_FORM:
            return {
                ...prevState,
                selectedLicense: value,
                showType: licenseMgmtActions.SHOW_LICENSE_FORM,
            };
        case licenseMgmtActions.SHOW_DELETE_LICENSE:
            return {
                ...prevState,
                selectedLicense: value,
                showType: licenseMgmtActions.SHOW_DELETE_LICENSE,
            };
        case licenseMgmtActions.RESET:
        case licenseMgmtActions.CLOSE_FORM:
            return INITIAL_STATE;
        default:
            return INITIAL_STATE

    }
}

export const licenseMgmtAtom = atomWithReducer(INITIAL_STATE, licenseMgmtReducer);