import React from 'react';
import {useUserInfo} from '../../hooks/useAuth';
import ImagesTable from "./ImagesTable";
import {useFetchGenericImages} from "../../hooks/useFetchGenericImages";
import {useFetchTenantImages} from "../../hooks/useFetchTenantImages";
import {Loader} from "semantic-ui-react";


const Images = (props) => {
	const {userdata, multitenant} = useUserInfo();

	const {
		data: genericImages,
		refetch: refetchGenericImages
	} = useFetchGenericImages({
		options: {
			refetchOnMount: 'always'
		}
	})

	const {
		data: tenantImages,
		isFetched: tenantImagesIsFetched,
		refetch: refetchTenantImages
	} = useFetchTenantImages({
		tenantId: userdata.tenantid,
		options: {
			refetchOnMount: 'always',
			refetchInterval: 20000,
			refetchIntervalInBackground: true,
			retry: false
		}
	})

	return (
		!tenantImagesIsFetched ? <Loader size='big' active inline='centered' style={{marginTop: '10rem'}}/> :
			<>
				<ImagesTable
					genericImages={genericImages}
					tenantImages={tenantImages}
					refetchTenantImages={refetchTenantImages}
				/>
			</>
	);
};

export default Images;
