import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import _ from 'lodash'
import MeasurementStatus from './MeasurementStatus';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { useFetchFullNetworkConfig } from '../../../hooks/useFetchFullNetworkConfig';
import { useFetchProbeModule } from '../../../hooks/useFetchProbeModule';
import Crontab from '../../../components/crontab/Crontab';
import { useFormFields } from '../../../hooks/useFormFields';
import NMFieldValidator from '../../../utils/NMFieldValidator';
import {editProbeAtom} from "../../../store/editProbe";
import {useAtomValue} from "jotai";

function RunningConfig(props) {
    const moduleData = useMemo(() => props.data, [props.data]);
    const hasPermission = props.hasPermission;
    const probeId = props.probeId;
    const defaultPeriodic = { randomoffset: "0", period: { minute: "*", hour: "*", dayOfMonth: "*", month: "*", dayOfWeek: "*" }}
    const probeState = useAtomValue(editProbeAtom);

    const {
        register,
        watch,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });
    const runmethodWatch = watch('runmethod');
    const runMethodOptions = [
        { key: 'ondemand', value: 'ondemand', text: 'On demand' },
        { key: 'periodic', value: 'periodic', text: 'Periodic' },
        { key: 'asservice', value: 'asservice', text: 'As service' },
    ]

    const {
        data: namespaceList,
        isLoading: namespaceListIsLoading
    } = useFetchFullNetworkConfig({
        probeid: probeId,
        select: data => {
            if (!("interfaces" in data && "routes" in data && "namespaces" in data)) {
                return ["default"]
            }
            let namespaceList = new Set([]);
            for (const iface of data.interfaces) {
                if (!namespaceList.has(iface.namespace)) {
                    if (namespaceList.has('') && !iface.namespace) {
                        continue;
                    }
                    namespaceList.add(iface.namespace);
                }
            }
            for (const r of data.routes) {
                if (!namespaceList.has(r.namespace)) {
                    if (namespaceList.has('') && !r.namespace) {
                        continue;
                    }
                    namespaceList.add(r.namespace);
                }
            }
            for (const ns of data.namespaces) {
                if (!namespaceList.has(ns.name)) {
                    namespaceList.add(ns.name);
                }
            }
            namespaceList.add("default")
            return [...namespaceList].filter(e => e).sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
        },
        options: {
            refetchOnMount: "always",
        },
    });
    const getNamespaceOptions = () => !namespaceList ? [] : namespaceList.map(e => ({ key: e, value: e, text: e }))

    const {
        data: nodeModuleData,
        refetch: refetchNodeModuleData,
        isLoading: nodeModuleDataIsLoading,
    } = useFetchProbeModule({
        probeid: probeId,
        moduleid: moduleData.moduleid,
        instanceid: moduleData.instanceid,
        select: useCallback(data => {
            // let { runconfig, netconfig, group } = { ...data };
            let { runconfig, netconfig } = { ...data };
            try {
                runconfig = JSON.parse(data.runconfig);
                netconfig = JSON.parse(data.netconfig);
            } catch {
                runconfig = {};
                netconfig = {};
            }
            // return { ...runconfig, ...netconfig, group }
            return { ...runconfig, ...netconfig }
        }, []),
        options: {
            refetchOnMount: "always",
        },
    });

    const onSubmit = (values) => {
        const runconfig = { ...values };

        if (runmethodWatch !== 'periodic') {
            delete runconfig.period;
            delete runconfig.randomoffset;
        }

        if (runmethodWatch !== 'ondemand') {
            if (probeState.license.expiryDays < 0) {
                // Only switching running config to on demand is allowed if license is invalid
                props.noConfigChangeMessage();
                return;
            }
        }

        if (_.isEqual(runconfig, nodeModuleData)) {
            props.noConfigChangeMessage()
            return
        }

        delete runconfig.namespace;
        const namespace = { namespace: !!values.namespace ? values.namespace : 'default' };

        let data = {
            nodeid: probeId,
            moduleid: moduleData.moduleid,
            instanceid: moduleData.instanceid,
            runconfig: JSON.stringify(runconfig),
        };

        let ns = {
            nodeid: probeId,
            moduleid: moduleData.moduleid,
            instanceid: moduleData.instanceid,
            netconfig: JSON.stringify(namespace),
        };
        props.onFormSubmit(data, ns).then(() => refetchNodeModuleData())
    };

    const resetValues = useCallback(() => {
        if (nodeModuleData && Object.keys(nodeModuleData).length) {
            let { ...rest } = nodeModuleData;
            if (rest.runmethod !== 'periodic') {rest = ({ ...rest, ...defaultPeriodic })}
            reset(rest)
        }
    }, [nodeModuleData, reset]) // eslint-disable-line

    useEffect(() => {
        resetValues()
    }, [resetValues])

    return (nodeModuleDataIsLoading || namespaceListIsLoading) ? null : (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ margin: '0 2rem 0 2rem' }}>
            {moduleData.moduleid !== 'ethernetframework' &&
                <Form.Group inline widths={6}>
                    <Form.Field>Latest measurements</Form.Field>
                    <Form.Field width={12} style={{ paddingLeft: '0.2em' }}>
                        <MeasurementStatus probeId={probeId} data={moduleData}/>
                    </Form.Field>
                </Form.Group>}
            {(moduleData.parent === "" || props.top === "ethernetframework" || props.top === undefined || props.parentRunningConfig?.runmethod === 'asservice') ?
                (<div style={moduleData.hardlinked ? { pointerEvents: 'none' } : {}}>
                    <Form.Group widths={6}>
                        <Form.Field>Namespace</Form.Field>
                        {renderDropdown('Namespace', 'namespace', getNamespaceOptions(), { hideLabel: true })}
                    </Form.Group>
                    {moduleData.moduleid !== 'ethernetframework' && 
                        <Form.Group widths={6}>
                            <Form.Field>Run method</Form.Field>
                            {renderDropdown('Run method', 'runmethod', runMethodOptions, { hideLabel: true })}
                        </Form.Group>}
                    {runmethodWatch === 'periodic' && (
                            <>
                                <Crontab errors={errors} register={register} setValue={setValue} watch={watch}/>
                                <Form.Group widths={6}>
                                    <Form.Field>Max random offset time</Form.Field>
                                    {renderInput('Max random offset time', 'randomoffset', { validate: (v) => NMFieldValidator.validateRandomoffset(v), unit: 's', hideLabel: true })}
                                </Form.Group>
                            </>
                        )}
                    {!moduleData.hardlinked ? <Form.Group>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Form.Button
                                size="small"
                                type="submit"
                                primary
                                content="Update"
                            />
                        </PermissionsGateV>
                        <PermissionsGateV hasPermission={hasPermission}>
                            <Form.Button
                                size="small"
                                type="button"
                                onClick={() => resetValues()}
                                content="Reset"
                            />
                        </PermissionsGateV>
                    </Form.Group> : null}
                </div>) : null}
        </Form>
    )
}

export default RunningConfig;
