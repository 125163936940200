const EXPIRY_DAYS_WARNING = 30;

const getExpiryDays = (issueDate, warranty) => {
	if (warranty) {
		let timeUnitChar = warranty.slice(-1);
		let warrantyDuration = Number(warranty.slice(0, -1));

		let date = new Date();
		date.setHours(0, 0, 0, 0);

		const expiryDate = new Date(issueDate);
		expiryDate.setHours(0, 0, 0, 0);

		switch (timeUnitChar) {
			case 'M':
				expiryDate.setMonth(expiryDate.getMonth() + warrantyDuration);
				break;
			case 'Y':
				expiryDate.setFullYear(expiryDate.getFullYear() + warrantyDuration);
				break;
			default:
				break;
		}

		return Math.floor((expiryDate.getTime() - date.getTime()) / (60 * 60 * 1000 * 24));
	}

	return null;
};

const getExpiryColor = (expiryDays, isIcon = false) => {
	if (expiryDays == null) return null;
	if (expiryDays < 0) return 'red';
	if (expiryDays <= EXPIRY_DAYS_WARNING && isIcon) return 'yellow';
	if (expiryDays <= EXPIRY_DAYS_WARNING) return '#DAA520';
	return null;
};

export { getExpiryDays, getExpiryColor, EXPIRY_DAYS_WARNING };
