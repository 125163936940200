import {useQuery} from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchTenantImages = ({
		tenantId,
		select,
		options
	} = {}) => {
	const query = useQuery({
		queryKey: ['getTenantImages'],
		queryFn: () =>
			NMService.getTenantImages(tenantId)
				.then(r => r.data)
				.catch(e => []),
		placeholderData: (data) => data ?? [],
		staleTime: Infinity,
		select,
		...options
	})
	return {...query, data: query.data ?? []}
}