import React, { useState } from 'react';
import RawConfig from './RawConfig';
import Configurator from './Configurator';
import SnmpModule from './SnmpModule';

const ModuleConfig = (props) => {
    const { nodeid, moduleid, instanceid, version, hardlinked } = props.data;
    const hasPermission = props.hasPermission;
    const [renderAsRawJson, setRenderAsRawJson] = useState(false);

    const switchView = () => {
        setRenderAsRawJson((prevState) => !prevState);
    };

    return (
        <React.Fragment>
            {!renderAsRawJson ? (
                <div style={hardlinked ? { pointerEvents: 'none' } : {}}>
                    {moduleid !== 'snmp' ?
                        <Configurator
                            probeid={nodeid}
                            moduleid={moduleid}
                            instanceid={instanceid}
                            version={version}
                            hardlinked={hardlinked}
                            hasPermission={hasPermission }
                            switchView={switchView}
                            onFormSubmit={props.onFormSubmit}
                            noConfigChangeMessage={props.noConfigChangeMessage}
                        /> :
                        <SnmpModule
                            probeid={nodeid}
                            moduleid={moduleid}
                            instanceid={instanceid}
                            version={version}
                            hardlinked={hardlinked}
                            hasPermission={hasPermission}
                            switchView={switchView}
                            onFormSubmit={props.onFormSubmit}
                            noConfigChangeMessage={props.noConfigChangeMessage}
                        />}
                </div>
            ) : (
                <RawConfig
                    probeid={nodeid}
                    moduleid={moduleid}
                    hardlinked={hardlinked}
                    instanceid={instanceid}
                    version={version}
                    hasPermission={hasPermission}
                    switchView={switchView}
                    onFormSubmit={props.onFormSubmit}
                    noConfigChangeMessage={props.noConfigChangeMessage}
                />
            )}
        </React.Fragment>
    );
};

export default ModuleConfig;
