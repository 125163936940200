import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Dropdown, Form, Grid, Icon, Input } from 'semantic-ui-react';
import NMService from '../../../services/nm.service';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import ModuleBoxLayout from '../../../layouts/ModuleBox/ModuleBox';
import AnchorStyleButton from '../../../layouts/AnchorStyleButton/AnchorStyleButton';
import { useAtomValue, useSetAtom } from 'jotai';
import { PermissionsGateV } from '../../../layouts/PermissionGate/PermissionGateV';
import { addGlobalMessageAtom } from '../../../store/globalMessage';
import { useFetchSwModules } from '../../../hooks/useFetchSwModules';
import { useFetchProbeModuleBundle } from '../../../hooks/useFetchProbeModuleBundle';
import { installedModulesAtom } from '../../../store/installedModules';
import { ErrorMessage } from '../../../components/ErrorMessage';
import NMFieldValidator from '../../../utils/NMFieldValidator';
import {useAtom} from "jotai/index";
import {editProbeAtom} from "../../../store/editProbe";

const InstalledModule = (props) => {
    const {
        register,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const probeId = useMemo(() => props.probeId, [props.probeId]);
    const moduleData = useMemo(() => props.moduleData, [props.moduleData]);
    const [probeState, setProbeState] = useAtom(editProbeAtom);

    const modulesState = useAtomValue(installedModulesAtom);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const [state, setState] = useState({
        showDeleteModal: false,
        metapackage: false,
        submodules: []
    })

    const {
        data: moduleList,
    } = useFetchSwModules({
        options: {
            initialData: (data) => data ?? []
        }
    });

    const {
        refetch: refetchModuleBundle
    } = useFetchProbeModuleBundle({ probeid: probeId });

    const onDeletewModule = async () => {
        const { nodeid, module, version } = moduleData;
        await NMService.deleteInstalledModule(nodeid, module, version).then(() => {
            addGlobalMessage({
                header: 'Delete installed module',
                content: `Module was successfully deleted.`,
                type: 'positive',
            }
            );
            refetchModuleBundle();
        }).catch(e => null)
    };

    const onAddModuleInstance = async (values) => {
        let submodule = null;
        let subversion = null;
        if (state.metapackage && values.submodule) {
            const [module, version] = values.submodule.split('_');
            submodule = module;
            subversion = version;
        }

        let newModuleData = {
            nodeid: moduleData.nodeid,
            moduleid: (state.metapackage && submodule) ? submodule : moduleData.module,
            version: (state.metapackage && subversion) ? subversion : moduleData.version,
            instanceid: values.instanceid,
            group: [],
            permissions: { managedby: 'anyone' },
        };

        await NMService.assignModule(newModuleData).then(() => {
            addGlobalMessage({
                header: 'Add new module instance',
                content: `Module instance was successfully added.`,
                type: 'positive',
            });
            refetchModuleBundle();
        }).catch(e => null)
    };

    const getSubModuleOptions = () => {
        const options = [];
        moduleList.forEach(({ id, version, description }) => {
            if (state.submodules.includes(id)) {
                options.push({
                    key: `${id}_${version}`,
                    value: `${id}_${version}`,
                    text: `${id} ${version}  -  ${description}`,
                })
            }
        })
        return options
    }

    useEffect(() => {
        if (!moduleList && !Array.isArray(moduleList)) {
            return;
        }

        const foundIndex = moduleList.findIndex(e => e.id === moduleData.module);
        if (foundIndex > -1) {
            const { data } = moduleList[foundIndex];
            const { metapackage, submodules } = data;
            setState(prevState => ({ ...prevState, metapackage, submodules }))
        }
    }, [moduleList, moduleData.module]);

    return (
        <Fragment>
            <ModuleBoxLayout>
                <Grid padded>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            {moduleData.module} (v{moduleData.version})
                        </Grid.Column>
                        <Grid.Column width={5}></Grid.Column>
                        <Grid.Column width={7}>
                            <PermissionsGateV hasPermission={modulesState.hasPermission && probeState.license.expiryDays >= 0}>
                                <AnchorStyleButton onClick={() => setState(prevState => ({ ...prevState, showForm: true }))}>
                                    <Icon name="plus" link />
                                    add instance
                                </AnchorStyleButton>
                            </PermissionsGateV>
                            &emsp;
                            <PermissionsGateV hasPermission={(modulesState.hasPermission)}>
                                <AnchorStyleButton
                                    disabled={props.disabled}
                                    onClick={() => setState(prevState => ({ ...prevState, showDeleteModal: true }))}
                                >
                                    <Icon name="trash" /> delete
                                </AnchorStyleButton>
                            </PermissionsGateV>
                            &emsp; &emsp;&emsp;
                        </Grid.Column>
                    </Grid.Row>
                    {state.showForm && <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Form
                                className="basic segment"
                                onSubmit={handleSubmit(onAddModuleInstance)}>
                                <Form.Field error={Boolean(errors.instanceid)} width={6}>
                                    <label>Instance name</label>
                                    <Input
                                        {...register("instanceid", {
                                            validate: (value) =>
                                                NMFieldValidator.validateString(
                                                    value,
                                                    "^[a-zA-Z0-9_-]{1,16}$",
                                                    1,
                                                    16,
                                                    false,
                                                    "Instance ID can contain only ASCII letters, numbers, _ and -"
                                                ),
                                            required: true,
                                        })}
                                        placeholder="Instance name"
                                        onChange={(_, { name, value }) => {
                                            setValue(name, value);
                                        }}
                                    />
                                    {errors.instanceid && <ErrorMessage>{errors.instanceid.message}</ErrorMessage>}
                                </Form.Field>
                                {state.metapackage &&
                                    <Form.Field error={Boolean(errors.submodule)}>
                                        <label align="right">Submodule</label>
                                        <Controller
                                            name="submodule"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field, ref }) => (
                                                <Dropdown
                                                    {...field}
                                                    ref={ref}
                                                    fluid
                                                    selection
                                                    placeholder="Select submodule"
                                                    onChange={(_, { value }) => field.onChange(value)}
                                                    options={getSubModuleOptions()}
                                                />
                                            )}
                                        />
                                    </Form.Field>
                                }
                                <PermissionsGateV
                                    hasPermission={(modulesState.hasPermission)}
                                >
                                    <Button
                                        size="small"
                                        primary
                                    >Add
                                    </Button>
                                </PermissionsGateV>
                                <PermissionsGateV
                                    hasPermission={(modulesState.hasPermission)}
                                >
                                    <Button
                                        size="small"
                                        type="button"
                                        onClick={() => setState(prevState => ({ ...prevState, showForm: false }))}
                                    >
                                        Cancel
                                    </Button>
                                </PermissionsGateV>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>}
                </Grid>
            </ModuleBoxLayout>
            <ConfirmationModal
                open={state.showDeleteModal}
                header="Delete installed module"
                content="Are you sure you want to delete installed module?"
                onConfirm={onDeletewModule}
                onDismiss={() => setState(prevState => ({ ...prevState, showDeleteModal: false }))}
            />
        </Fragment>
    );
};

export default InstalledModule;
