import {useQuery} from "@tanstack/react-query";
import NMService from "../services/nm.service";

export const useFetchGenericImages = ({
		select,
		options
	} = {}) => {
	const query = useQuery({
		queryKey: ['getGenericImages'],
		queryFn: () =>
			NMService.getGenericImages()
				.then(r => r.data)
				.catch(e => []),
		select,
		...options
	})
	return {...query, data: query.data ?? []}
}